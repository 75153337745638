import React from 'react';
import { ReactComponent as Logo } from './assets/images/logo-red.svg';

import './App.scss';

const App = () => {
  return (
    <div className="site">
      <Logo className="coming-soon"/>
    </div>
  );
}

export default App;
